<!--
 * @Author: zhangjingqing
 * @Date: 2022-05-14 23:02:43
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-22 09:43:27
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\dataTableManage\TreeModal.vue
-->
<template>
  <a-modal
    title="选择要复制到的目标目录"
    :width="800"
    :visible="visible"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="目标目录">
        <a-tree-select
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          :replaceFields="{
            children: 'children',
            title: 'menuName',
            key: 'id',
            value: 'id',
          }"
          placeholder="请选择目标目录"
          tree-default-expand-all
          v-decorator="[
            'id',
            { rules: [{ required: true, message: '目录不能为空!' }] },
          ]"
        >
        </a-tree-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { queryAllCatalogmenu } from "@/api/cata";
import { copyTab } from "@/api/table";
import { getParents, getLabel } from "@/utils/utils";
import { indexStandardType, taxpayerNature, dataType } from "@/utils/dict";
export default {
  name: "",
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this, { name: "form" }),
      initialTreeData: [],
      treeData: [],
      checkedNodes: [],
      //   id: "",
    };
  },
  methods: {
    show(checkedNodes) {
      this.checkedNodes = checkedNodes;
      this.queryList();
      this.visible = true;
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let enName = [];
          this.checkedNodes.forEach((item) => {
            enName.push(item.menuEnName);
          });
          let curYear = getParents(
            this.initialTreeData,
            this.checkedNodes[0].id
          )[6].menuName;
          let parents = getParents(this.initialTreeData, values.id);
          let tabExcelVo = {
            year: parents[5].menuName,
            taxpayerNature: getLabel(
              parents[4].menuName,
              taxpayerNature,
              "label",
              "value"
            ),
            indexStandardType: getLabel(
              parents[3].menuName,
              indexStandardType,
              "label",
              "value"
            ),
            dateType: getLabel(parents[2].menuName, dataType, "label", "value"),
            excelName: parents[1].menuName,
            sheetName: parents[0].menuName,
          };

          let params = {
            catalogId: values.id,
            enName: enName,
            newYear: curYear == tabExcelVo.year ? "" : tabExcelVo.year,
            tabExcelVo,
          };
          let res = await copyTab(params);
          if (res.status == 200) {
            this.$message.success("复制成功！");
            this.$emit("reload");
            this.visible = false;
          }
        }
      });
    },
    handleCancel() {
      this.form.resetFields();
      this.visible = false;
    },
    async queryList() {
      let res = await queryAllCatalogmenu({
        category: 1,
      });
      if (res.status == 200) {
        this.initialTreeData = res.data;
        let treeData = JSON.parse(JSON.stringify(res.data));
        this.travelTree(treeData);
        this.treeData = treeData;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    travelTree(datas) {
      for (var i in datas) {
        let node = datas[i];
        if (node.type != 6) {
          node.disabled = true;
        } else {
          delete node.children;
        }
        if (datas[i].children) {
          this.travelTree(datas[i].children);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>