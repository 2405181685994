var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"选择要复制到的目标目录","width":800,"visible":_vm.visible},on:{"ok":_vm.handleSave,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 }}},[_c('a-form-item',{attrs:{"label":"目标目录"}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'id',
          { rules: [{ required: true, message: '目录不能为空!' }] },
        ]),expression:"[\n          'id',\n          { rules: [{ required: true, message: '目录不能为空!' }] },\n        ]"}],attrs:{"dropdown-style":{ maxHeight: '400px', overflow: 'auto' },"tree-data":_vm.treeData,"replaceFields":{
          children: 'children',
          title: 'menuName',
          key: 'id',
          value: 'id',
        },"placeholder":"请选择目标目录","tree-default-expand-all":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }