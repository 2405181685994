/*
 * @Author: zhangjingqing
 * @Date: 2022-03-05 22:45:19
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-07 19:25:10
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\cata.js
 */
import { getAction, postAction } from "./http/manage";

const URL = "";
// 查询目录树
const queryAllCatalogmenu = (params) =>
  getAction(URL + "/catalog-menu/all-catalogmenu", params);
// 新增目录树
const insertCatalogmenu = (params) =>
  postAction(URL + "/catalog-menu/insert-catalogmenu", params);
// 修改目录树
const updateCatalogmenu = (params) =>
  postAction(URL + "/catalog-menu/update-catalogmenu", params);
// 删除目录树
const deleteCatalogmenu = (params) =>
  postAction(URL + "/catalog-menu/del-catalogmenu", params);
// 复制目录树
const copyCatalogmenu = (params) =>
  postAction(URL + "/catalog-menu/copy-catalogmenu", params);
// 一键复制（需目录为空）
const copyAll = (params) => postAction(URL + "/common/copy-all", params);
export {
  queryAllCatalogmenu,
  insertCatalogmenu,
  updateCatalogmenu,
  deleteCatalogmenu,
  copyCatalogmenu,
  copyAll,
};
