<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-01 22:33:14
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-04 17:51:20
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\dataTableManage\Index.vue
-->
<template>
  <div class="container">
    <a-row :gutter="20">
      <a-col :span="12">
        <div class="wrapper">
          <div style="margin-bottom: 10px">
            <a-button type="primary" icon="copy" @click="handleCopy"
              >复制数据表</a-button
            >
          </div>
          <a-spin :spinning="loading" class="tree">
            <a-tree
              checkable
              show-icon
              :blockNode="true"
              :tree-data="treeData"
              :expandedKeys.sync="expandedKeys"
              :checkedKeys.sync="checkedKeys"
              :replaceFields="{
                children: 'children',
                title: 'menuName',
                key: 'id',
                value: 'id',
              }"
              @check="onCheck"
              @select="onSelect"
            >
              <template slot="custom" slot-scope="item">
                <div class="node">
                  <span class="node-title"
                    ><a-icon
                      type="folder-open"
                      v-if="expandedKeys.includes(item.id)"
                    />
                    <a-icon type="file-text" v-else-if="item.type == 7" />
                    <a-icon type="folder" v-else />
                    {{ item.menuName }}
                  </span>
                  <span class="icon-wrap" v-if="item.type == 7">
                    <a-tooltip>
                      <template slot="title">删除</template>
                      <a-icon type="delete" @click="handleDelete(item)" />
                    </a-tooltip>
                  </span>
                  <span class="icon-wrap" v-if="item.type == 7">
                    <a-tooltip>
                      <template slot="title">编辑</template>
                      <a-icon type="form" @click="handleEdit(item)" />
                    </a-tooltip>
                  </span>
                  <span class="icon-wrap" v-if="item.type == 6">
                    <a-tooltip>
                      <template slot="title">新增数据表</template>
                      <a-icon type="plus" @click="handleAdd(item.id)" />
                    </a-tooltip>
                  </span>
                </div>
              </template>
            </a-tree>
          </a-spin>
        </div>
      </a-col>
      <a-col :span="12" class="detail">
        <a-card title="数据表详情" :bordered="false" v-if="detailVisible">
          <a slot="extra" href="#"><a-icon type="question-circle" /></a>
          <a-descriptions title="所属目录" :column="4">
            <a-descriptions-item label="年份">{{
              cataInfo.year
            }}</a-descriptions-item>
            <a-descriptions-item label="纳税人类型">{{
              cataInfo.taxpayer
            }}</a-descriptions-item>
            <a-descriptions-item label="指标准则类型">{{
              cataInfo.type
            }}</a-descriptions-item>
            <a-descriptions-item label="时间类型">{{
              cataInfo.dateType
            }}</a-descriptions-item>
            <a-descriptions-item label="对应excel">{{
              cataInfo.excel
            }}</a-descriptions-item>
            <a-descriptions-item label="对应sheet">{{
              cataInfo.sheet
            }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="数据表信息" :column="4">
            <a-descriptions-item label="数据表名">{{
              tableInfo.cnname
            }}</a-descriptions-item>
            <a-descriptions-item label="表英文名" span="3">{{
              tableInfo.enname
            }}</a-descriptions-item>
            <a-descriptions-item label="开始行">{{
              tableInfo.startRow
            }}</a-descriptions-item>
            <a-descriptions-item label="结束行">{{
              tableInfo.endRow
            }}</a-descriptions-item>
            <a-descriptions-item label="开始列">{{
              tableInfo.startCol
            }}</a-descriptions-item>
            <a-descriptions-item label="结束列">{{
              tableInfo.endCol
            }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions
            title="特殊时间取值"
            :column="4"
            v-if="specialTimeVo != null"
          >
            <a-descriptions-item label="开始时间">{{
              formatSpecialTime(specialTimeVo.beginDate)
            }}</a-descriptions-item>
            <a-descriptions-item label="结束时间" span="3">{{
              formatSpecialTime(specialTimeVo.endDate)
            }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="列信息" :column="3"> </a-descriptions>
          <a-table :columns="columns" :data-source="data" :pagination="false">
            <span slot="dataType" slot-scope="dataType">
              {{
                colTypes.filter((item) => item.dataType == dataType)[0].comments
              }}
            </span>
            <span slot="ifQueryCol" slot-scope="ifQueryCol">
              {{ formatIfquerycol(ifQueryCol) }}
            </span>
          </a-table>
        </a-card>
        <div class="empty" v-else>
          <a-empty />
        </div>
      </a-col>
    </a-row>
    <DataModal ref="dataModal" @reload="queryList"></DataModal>
    <TreeModal ref="treeModal" @reload="queryList"></TreeModal>
  </div>
</template>
<script>
import { queryAllCatalogmenu } from "@/api/cata";
import { getTabDetail } from "@/api/col";
import { queryColTypes, delTab } from "@/api/table";
import DataModal from "./DataModal";
import TreeModal from "./TreeModal";
import { getParents } from "@/utils/utils";
import pick from "lodash.pick";
const columns = [
  {
    title: "对应sheet列",
    key: "colSheet",
    dataIndex: "colSheet",
  },
  {
    title: "列中文名",
    dataIndex: "colCnName",
    key: "colCnName",
  },
  {
    title: "列英文名",
    dataIndex: "colEnName",
    key: "colEnName",
  },
  {
    title: "数据类型",
    key: "dataType",
    dataIndex: "dataType",
    scopedSlots: { customRender: "dataType" },
  },
  {
    title: "长度",
    key: "length",
    dataIndex: "length",
  },
  {
    title: "是否为查询列",
    key: "ifQueryCol",
    dataIndex: "ifQueryCol",
    align: "center",
    scopedSlots: { customRender: "ifQueryCol" },
  },
];

export default {
  name: "",
  components: { DataModal, TreeModal },
  data() {
    return {
      loading: false,
      treeData: [],
      expandedKeys: [],
      checkedKeys: [],
      checkedNodes: [],
      detailVisible: false,
      cataInfo: {
        year: "-",
        type: "-",
        dateType: "-",
        excel: "-",
        sheet: "-",
      },
      tableInfo: {
        cnname: "-",
        enname: "-",
        startRow: "-",
        endRow: "-",
        startCol: "-",
        endCol: "-",
      },
      specialTimeVo: {},
      data: [],
      columns,
      colTypes: [],
    };
  },
  mounted() {
    this.queryColTypes();
    this.queryList(1);
  },
  methods: {
    async queryList(initial) {
      this.loading = true;
      let res = await queryAllCatalogmenu({
        category: 1,
      });
      if (res.status == 200) {
        if (initial == 1) {
          this.expandedKeys = [];
        }
        let data = res.data;
        this.travelTree(data, initial);
        this.treeData = data;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleAdd(pid) {
      this.$refs.dataModal.show(2, pid, getParents(this.treeData, pid));
    },
    handleEdit(item) {
      this.$refs.dataModal.show(
        3,
        item.parentId,
        getParents(this.treeData, item.parentId),
        item.menuEnName
      );
    },
    async handleCopy() {
      let len = this.checkedNodes.length;
      if (len < 1) {
        return this.$message.warning("请选择节点！");
      }
      this.$refs.treeModal.show(this.checkedNodes);
    },
    handleDelete(record) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let params = Object.assign(
            {},
            pick(record, "detailId", "id", "menuEnName", "menuName")
          );
          let res = await delTab(params);
          if (res.status == 200) {
            that.$message.success("删除成功！");
            that.queryList();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
    onCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys;
      let checkedNodes = e.checkedNodes;
      let nodes = [];
      checkedNodes.forEach((item) => {
        let node = item.data.props.dataRef;
        if (node.type == 7) nodes.push(item.data.props.dataRef);
      });
      this.checkedNodes = nodes;
    },
    async onSelect(keys, event) {
      let node = event.node.dataRef;
      if (node.type == 7) {
        this.detailVisible = true;
        let id = node.id;
        let parents = getParents(this.treeData, id);
        let flag = parents.length == 8;
        this.cataInfo = {
          year: parents[flag ? 6 : 5].menuName,
          taxpayer: flag ? parents[5].menuName : "-",
          type: flag ? parents[4].menuName : "-",
          dateType: parents[3].menuName,
          excel: parents[2].menuName,
          sheet: parents[1].menuName,
        };
        let menuEnName = node.menuEnName;
        let res = await getTabDetail({ enName: menuEnName });
        if (res.status == 200) {
          let accessRule = res.data.accessRuleVo;
          this.tableInfo = {
            cnname: res.data.tabListVo.tableCnName,
            enname: res.data.tabListVo.tableEnName,
            startRow: accessRule.startRow,
            endRow: accessRule.endRow,
            startCol: accessRule.startCol,
            endCol: accessRule.endCol,
          };
          this.specialTimeVo = res.data.specialTimeVo;
          let data = res.data.tabListVo.colListVos;
          this.data = data;
        } else {
          this.$notification.error({
            message: "系统提示",
            description: res.msg || res.message,
            duration: 4,
          });
        }
      } else {
        this.detailVisible = false;
      }
    },
    travelTree(datas, initial) {
      for (var i in datas) {
        let node = datas[i];
        node.scopedSlots = { title: "custom" };
        if (node.type < 3 && initial == 1) {
          this.expandedKeys.push(node.id);
        } else if (node.type > 6) {
          node.isLeaf = true;
        }
        if (datas[i].children) {
          this.travelTree(datas[i].children, initial);
        }
      }
    },
    async queryColTypes() {
      let res = await queryColTypes();
      if (res.status == 200) {
        this.colTypes = res.data;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    formatIfquerycol(value) {
      if (value === 1) {
        return "否";
      } else if (value === 0) {
        return "是";
      }
    },
    formatSpecialTime(val) {
      let label = "";
      if (this.cataInfo.dateType == 1) {
        switch (val) {
          case 1:
            label = "一季度";
            break;
          case 2:
            label = "一季度";
            break;
          case 3:
            label = "一季度";
            break;
          case 4:
            label = "一季度";
            break;
        }
      } else {
        switch (val) {
          case 1:
            label = "一月";
            break;
          case 2:
            label = "二月";
            break;
          case 3:
            label = "三月";
            break;
          case 4:
            label = "四月";
            break;
          case 5:
            label = "五月";
            break;
          case 6:
            label = "六月";
            break;
          case 7:
            label = "七月";
            break;
          case 8:
            label = "八月";
            break;
          case 9:
            label = "九月";
            break;
          case 10:
            label = "十月";
            break;
          case 11:
            label = "十一月";
            break;
          case 12:
            label = "十二月";
            break;
        }
      }
      return label;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  min-height: 280px;
  .wrapper {
    padding: 20px;
    background: #fff;
    .tree {
      height: 930px;
      overflow-y: auto;
    }
    .node {
      .icon-wrap {
        display: none;
        float: right;
        margin-left: 5px;
      }
      &:hover {
        .icon-wrap {
          display: block;
        }
      }
    }
  }
}
.options {
  padding-bottom: 16px;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 800px;
  background: #fff;
}
.detail {
  // position: fixed;
  // top: 144px;
  // right: 0;
}
</style>
