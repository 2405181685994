<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-05 22:13:33
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-22 09:42:32
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\dataTableManage\DataModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="1000"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-descriptions title="基本信息" :column="3"> </a-descriptions>
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="所属目录">
        <a-input v-decorator="['cata']" disabled />
      </a-form-item>
      <a-form-item label="数据表名">
        <a-input
          v-decorator="[
            'tableCnName',
            { rules: [{ required: true, message: '数据表名不能为空' }] },
          ]"
          placeholder="请输入数据表名"
          :disabled="status != 2"
          @change="handleTableCnNameChange"
        />
      </a-form-item>
      <a-form-item label="表英文名">
        <a-input
          v-decorator="[
            'tableEnName',
            { rules: [{ required: true, message: '表英文名不能为空' }] },
          ]"
          placeholder="请输入表英文名"
          disabled
        />
      </a-form-item>
      <a-form-item label="行范围" style="margin-bottom: 0">
        <a-form-item
          :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
        >
          <a-input
            v-decorator="[
              'startRow',
              { rules: [{ required: true, message: '开始行不能为空' }] },
            ]"
            placeholder="请输入开始行"
            type="number"
          />
        </a-form-item>
        <span
          :style="{
            display: 'inline-block',
            width: '24px',
            textAlign: 'center',
          }"
        >
          -
        </span>
        <a-form-item
          :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
        >
          <a-input
            v-decorator="['endRow']"
            placeholder="请输入结束行"
            type="number"
          />
        </a-form-item>
      </a-form-item>
      <a-form-item label="列范围" style="margin-bottom: 0">
        <a-form-item
          :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
        >
          <a-select v-decorator="['startCol']" placeholder="请选择开始列">
            <a-select-option v-for="col in cols" :key="col" :value="col">
              {{ col }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <span
          :style="{
            display: 'inline-block',
            width: '24px',
            textAlign: 'center',
          }"
        >
          -
        </span>
        <a-form-item
          :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
        >
          <a-select v-decorator="['endCol']" placeholder="请选择结束列">
            <a-select-option v-for="col in cols" :key="col" :value="col">
              {{ col }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form-item>
      <a-descriptions
        title="特殊时间取值"
        :column="3"
        v-if="this.dateType != 0"
      >
      </a-descriptions>
      <a-form-item label="开始时间" v-if="this.dateType != 0">
        <a-select
          v-decorator="['beginDate']"
          placeholder="请选择开始时间"
          v-if="this.dateType == 1"
        >
          <a-select-option :value="1"> 一季度 </a-select-option>
          <a-select-option :value="2"> 二季度 </a-select-option>
          <a-select-option :value="3"> 三季度 </a-select-option>
          <a-select-option :value="4"> 四季度 </a-select-option>
        </a-select>
        <a-select
          v-decorator="['beginDate']"
          placeholder="请选择开始时间"
          v-else
        >
          <a-select-option :value="1"> 一月 </a-select-option>
          <a-select-option :value="2"> 二月 </a-select-option>
          <a-select-option :value="3"> 三月 </a-select-option>
          <a-select-option :value="4"> 四月 </a-select-option>
          <a-select-option :value="5"> 五月 </a-select-option>
          <a-select-option :value="6"> 六月 </a-select-option>
          <a-select-option :value="7"> 七月 </a-select-option>
          <a-select-option :value="8"> 八月 </a-select-option>
          <a-select-option :value="9"> 九月 </a-select-option>
          <a-select-option :value="10"> 十月 </a-select-option>
          <a-select-option :value="11"> 十一月 </a-select-option>
          <a-select-option :value="12"> 十二月 </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="结束时间" v-if="this.dateType">
        <a-select
          v-decorator="['endDate']"
          placeholder="请选择结束时间"
          v-if="this.dateType == 1"
        >
          <a-select-option :value="1"> 一季度 </a-select-option>
          <a-select-option :value="2"> 二季度 </a-select-option>
          <a-select-option :value="3"> 三季度 </a-select-option>
          <a-select-option :value="4"> 四季度 </a-select-option>
        </a-select>
        <a-select v-decorator="['endDate']" placeholder="请选择结束时间" v-else>
          <a-select-option :value="1"> 一月 </a-select-option>
          <a-select-option :value="2"> 二月 </a-select-option>
          <a-select-option :value="3"> 三月 </a-select-option>
          <a-select-option :value="4"> 四月 </a-select-option>
          <a-select-option :value="5"> 五月 </a-select-option>
          <a-select-option :value="6"> 六月 </a-select-option>
          <a-select-option :value="7"> 七月 </a-select-option>
          <a-select-option :value="8"> 八月 </a-select-option>
          <a-select-option :value="9"> 九月 </a-select-option>
          <a-select-option :value="10"> 十月 </a-select-option>
          <a-select-option :value="11"> 十一月 </a-select-option>
          <a-select-option :value="12"> 十二月 </a-select-option>
        </a-select>
      </a-form-item>
      <a-descriptions title="列信息" :column="3"> </a-descriptions>
      <div class="btns">
        <a-space>
          <a-button type="primary" icon="plus" @click="insertEvent(-1)"
            >新增列</a-button
          >
          <a-button icon="delete" @click="removeEvent">删除选中</a-button>
        </a-space>
      </div>
      <vxe-table
        ref="xTable"
        border
        resizable
        show-overflow
        :data="tableData"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
      >
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column field="colSheet" title="对应sheet列" :edit-render="{}">
          <template #default="{ row }">
            <span>{{ row.colSheet }}</span>
          </template>
          <template #edit="{ row }">
            <vxe-select v-model="row.colSheet" transfer>
              <vxe-option
                v-for="item in cols"
                :key="item"
                :value="item"
                :label="item"
              ></vxe-option>
            </vxe-select>
          </template>
        </vxe-column>
        <vxe-column
          field="colCnName"
          title="列中文名"
          :edit-render="{ autofocus: '.vxe-input--inner' }"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model="row.colCnName"
              type="text"
              @change="handleColCnNameChange($event, row)"
            ></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="colEnName" title="列英文名">
          <template #edit="{ row }">
            <vxe-input
              v-model="row.colEnName"
              type="text"
              placeholder="请输入昵称"
            ></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="dataType" title="数据类型">
          <template #default="{ row }">
            <span>{{ formatColTypes(row.dataType) }}</span>
          </template>
          <template #edit="{ row }">
            <vxe-select v-model="row.dataType" transfer>
              <vxe-option
                v-for="item in colTypes"
                :key="item.dataType"
                :value="item.dataType"
                :label="item.comments"
              ></vxe-option>
            </vxe-select>
          </template>
        </vxe-column>
        <vxe-column field="length" title="长度" :edit-render="{}">
          <template #edit="{ row }">
            <vxe-input
              v-model="row.length"
              type="number"
              placeholder="请输入数值"
            ></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="ifQueryCol" title="是否为查询列" :edit-render="{}">
          <template #default="{ row }">
            <span>{{ formatIfquerycol(row.ifQueryCol) }}</span>
          </template>
          <template #edit="{ row }">
            <vxe-select
              v-model="row.ifQueryCol"
              transfer
              @change="validateUnique"
            >
              <vxe-option :key="0" :value="1" label="否"></vxe-option>
              <vxe-option :key="1" :value="0" label="是"></vxe-option>
            </vxe-select>
          </template>
        </vxe-column>
        <vxe-column field="sort" title="排序" :visible="false">
          <template #edit="{ row }">
            <vxe-input
              v-model="row.sort"
              type="number"
              placeholder="请输入序号"
            ></vxe-input>
          </template>
        </vxe-column>
      </vxe-table>
    </a-form>
  </a-modal>
</template>
<script>
import { createTab, queryColTypes, updateTab } from "@/api/table";
import { getTabDetail } from "@/api/col";
import { Pinyin } from "@/utils/ChinesePY";
import { indexStandardType, taxpayerNature, dataType } from "@/utils/dict";
import { getLabel } from "@/utils/utils";
export default {
  name: "dataModal",
  data() {
    return {
      columns: [],
      tableData: [],
      cols: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "AA",
        "AB",
        "AC",
        "AD",
        "AE",
        "AF",
        "AG",
        "AH",
        "AI",
        "AJ",
        "AK",
        "AL",
        "AM",
        "AN",
        "AO",
        "AP",
        "AQ",
        "AR",
        "AS",
        "AT",
        "AU",
        "AV",
        "AW",
        "AX",
        "AY",
        "AZ",
      ],
      status: 1,
      visible: false,
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
      pid: "",
      parents: [],
      colTypes: [],
      dateType: "",
      catalogMenuVo: {},
    };
  },
  methods: {
    show(status, pid, parents, menuEnName) {
      this.queryColTypes();
      this.pid = pid;
      this.parents = parents;
      this.status = status;
      this.visible = true;
      let paremtsArr = [];
      parents.forEach((item) => {
        paremtsArr.push(item.menuName);
      });
      let parentsStr = paremtsArr.reverse().join("/");
      this.dateType = dataType.filter(
        (item) => item.label == parents[2].menuName
      )[0].value;
      this.$nextTick(() => {
        this.form.setFieldsValue({ cata: parentsStr });
      });
      if (status == 3) {
        this.getTabDetail(menuEnName);
      }
    },
    async getTabDetail(menuEnName) {
      let res = await getTabDetail({ enName: menuEnName });
      if (res.status == 200) {
        let accessRuleVo = res.data.accessRuleVo;
        let specialTimeVo = res.data.specialTimeVo;
        let tabListVo = res.data.tabListVo;
        this.catalogMenuVo = res.data.catalogMenuVo;
        this.form.setFieldsValue(
          Object.assign({}, accessRuleVo, specialTimeVo, tabListVo)
        );
        this.$refs.xTable.loadData(tabListVo.colListVos);
      }
    },
    handleSave() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          if (!this.validateUnique()) {
            return;
          }
          let params;
          let accessRuleVo = {
            startRow: values.startRow,
            endRow: values.endRow,
            startCol: values.startCol,
            endCol: values.endCol,
          };
          let catalogMenuVo = {
            menuName: values.tableCnName,
            menuEnName: values.tableEnName,
            parentId: this.pid,
            type: 7,
          };
          let tabListVo = {
            tableCnName: values.tableCnName,
            tableEnName: values.tableEnName,
            colListVos: this.$refs.xTable.getTableData().tableData,
          };
          let parents = this.parents;
          let len = parents.length;
          let tabExcelVo = {
            year: len == 7 ? parents[5].menuName : parents[4].menuName,
            taxpayerNature:
              len == 7
                ? getLabel(
                    parents[4].menuName,
                    taxpayerNature,
                    "label",
                    "value"
                  )
                : -2,
            indexStandardType:
              len == 7
                ? getLabel(
                    parents[3].menuName,
                    indexStandardType,
                    "label",
                    "value"
                  )
                : -2,
            dateType: getLabel(parents[2].menuName, dataType, "label", "value"),
            excelName: parents[1].menuName,
            sheetName: parents[0].menuName,
          };
          let specialTimeVo = {
            beginDate: values.beginDate,
            dateType: this.dateType,
            endDate: values.endDate,
            type: 1,
            year: len == 7 ? parents[5].menuName : parents[4].menuName,
          };
          params = {
            accessRuleVo,
            catalogMenuVo,
            tabListVo,
            tabExcelVo,
          };

          if (this.dateType != 0 && values.beginDate && values.endDate) {
            params.specialTimeVo = specialTimeVo;
          }
          let res;
          if (this.status == 2) {
            res = await createTab(params);
          } else {
            params.catalogMenuVo = Object.assign(
              {},
              this.catalogMenuVo,
              catalogMenuVo
            );
            res = await updateTab(params);
          }
          if (res.status == 200) {
            this.$emit("reload");
            this.$message.success(
              this.status == 2 ? "新增成功！" : "修改成功！"
            );
            this.handleCancel();
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    },
    handleCancel() {
      this.tableData = [];
      this.form.resetFields();
      this.visible = false;
    },
    async queryColTypes() {
      let res = await queryColTypes();
      if (res.status == 200) {
        this.colTypes = res.data;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    formatColTypes(value) {
      if (value) {
        return this.colTypes.filter((item) => item.dataType == value)[0]
          .comments;
      }
      return "";
    },
    formatIfquerycol(value) {
      if (value === 1) {
        return "否";
      } else if (value === 0) {
        return "是";
      }
    },
    // 插入校验
    async insertEvent(row) {
      let len = this.$refs.xTable.getTableData().tableData.length;
      this.$refs.xTable
        .insertAt({ dataType: "varchar", ifQueryCol: 1, sort: len + 1 }, row)
        .then(({ row }) => this.$refs.xTable.setActiveRow(row));
    },
    removeEvent() {
      this.$refs.xTable.removeCheckboxRow();
    },
    handleTableCnNameChange(e) {
      let value = e.target.value;
      let len = this.parents.length;
      let year = len == 7 ? this.parents[5].menuName : this.parents[4].menuName;
      let enName =
        Pinyin.GetJP(value).toUpperCase() +
        "_" +
        this.getRandCode() +
        "_" +
        year;
      this.form.setFieldsValue({ tableEnName: enName });
    },
    handleColCnNameChange(e, row) {
      let value = e.value;
      row.colEnName = Pinyin.GetJP(value).toUpperCase();
    },
    validateUnique() {
      let data = this.$refs.xTable.getTableData().tableData;
      let len = data.length;
      let num = 0;
      data.forEach((item) => {
        let ifQueryCol = item.ifQueryCol;
        if (ifQueryCol == "0") {
          num++;
        }
      });
      if (len > 1 && num == 0) {
        this.$message.warning("查询列必须有一条！");
        return false;
      }
      if (num > 1) {
        this.$message.warning("查询列只能有一条！");
        return false;
      }
      return true;
    },
    getRandCode() {
      let str = "";
      for (let i = 0; i < 5; i++) {
        str += String.fromCharCode(
          Math.floor(Math.random() * 26) + "a".charCodeAt(0)
        ).toUpperCase();
      }
      return str;
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 2:
          title = "新建数据表";
          break;
        case 3:
          title = "编辑";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped>
.btns {
  margin-bottom: 10px;
}
</style>
