/*
 * @Author: zhangjingqing
 * @Date: 2022-04-02 18:40:22
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-01 17:09:12
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\utils\dict.js
 */
export const indexStandardType = [
  {
    label: "企业会计准则（2007）",
    value: 0,
  },
  {
    label: "企业会计准则（2019）",
    value: 1,
  },
  {
    label: "小企业会计准则（2013）",
    value: 2,
  },
];

export const taxpayerNature = [
  {
    label: "一般纳税人",
    value: 0,
  },
  {
    label: "小规模纳税人",
    value: 1,
  },
];

export const dataType = [
  {
    label: "年",
    value: 0,
  },
  {
    label: "季",
    value: 1,
  },
  {
    label: "月",
    value: 2,
  },
];

export const indexLevel = [
  {
    label: "一级指标",
    value: 1,
  },
  {
    label: "二级指标",
    value: 2,
  },
];

export const riskType = [
  {
    label: "无",
    value: 0,
  },
  {
    label: "低",
    value: 1,
  },
  {
    label: "中",
    value: 2,
  },
  {
    label: "高",
    value: 3,
  },
];

export const industry = [
  {
    label: "制造业",
    value: 0,
  },
  {
    label: "批发和零售业",
    value: 1,
  },
  {
    label: "现代服务业",
    value: 2,
  },
  {
    label: "其他",
    value: 3,
  },
];

export const registrationType = [
  {
    label: "有限责任公司",
    value: 0,
  },
  {
    label: "股份有限公司",
    value: 1,
  },
  {
    label: "其他",
    value: 2,
  },
];
