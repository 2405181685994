/*
 * @Author: zhangjingqing
 * @Date: 2022-03-15 23:00:02
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-10 17:11:44
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\table.js
 */
import { getAction, postAction } from "./http/manage";
const URL = "";

// 获取可选列集合类型
const queryColTypes = (params) =>
  getAction(URL + "/tab-operate/col-types", params);
// 获取表集合
const getTab = (params) => getAction(URL + "/tab-operate/get-tab", params);
// 新建表
const createTab = (params) =>
  postAction(URL + "/tab-operate/create-tab", params);
// 删除表目录及对应关系
const delTab = (params) => postAction(URL + "/tab-operate/del-data", params);
// 已存在表复制
const copyTab = (params) => postAction(URL + "/tab-operate/tab-copy", params);
// 修改表
const updateTab = (params) =>
  postAction(URL + "/tab-operate/update-tab", params);

export { queryColTypes, getTab, createTab, delTab, copyTab, updateTab };
