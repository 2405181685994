/*
 * @Author: zhangjingqing
 * @Date: 2022-03-31 21:50:17
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-01 16:53:49
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\col.js
 */
import { getAction, postAction } from "./http/manage";

const URL = "";
// 查询表详情
const getTabDetail = (params) => getAction(URL + "/col/get-tabDetail", params);
// 修改表取值规则
const updateTabCol = (params) => postAction(URL + "/col/update-tabCol", params);

export { getTabDetail, updateTabCol };
